import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';


import css from './ExtraCoach.module.css';
import { useHistory } from 'react-router-dom';

const CAROUSEL_DELAY_TIME = 10000;

const ExtraCoachSection = React.forwardRef((props, ref) => {

    const history = useHistory()
    return (
        <div className={classNames(css.root)}>
            <div className={classNames(css.root1)}>
                <div><h2>Start Your Coaching Journey Today!</h2>
                    <p>When you’re looking for the right coach for your staff, or reviewing your pool of
                        <br />coaches for a larger learning program, BOLDLY is here to help. Sign up to explore our
                        <br />marketplace and find the coaching skills and expertise you’re searching for.</p>
                </div>
                <div className={classNames(css.btnDiv)}>
                    <button onClick={()=>history.push('signup')} className={classNames(css.btn1)}>Sign Up</button>
                    <button onClick={()=>history.push('login')} className={classNames(css.btn2)}>Sign In</button>
                </div>
            </div>
        </div>
    );
});



export default ExtraCoachSection;
