import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../components';
import config from '../../config';

import css from './ComingSoonPopup.module.css';

const ComingSoonPopup = props => {
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    closeButtonMessage,
    connectMail,
    className,
  } = props;
  const { siteStaticLink } = config;
  const intl = useIntl();

  return (
    <Modal
      id="LandingPage.comingSoonModal"
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <div className={css.comingSoonTitle}>
        <FormattedMessage id="LandingPage.comingSoonTitle" />
      </div>
      <div className={css.comingSoonBody}>
        <FormattedMessage
          id="LandingPage.comingSoonBody"
          values={{
            mail: <a href={`mailto:${connectMail}`}>{connectMail}</a>,
            lineBreak: <br />,
            link: <a href={siteStaticLink}>{intl.formatMessage({ id: 'LandingPage.here' })}</a>,
          }}
        />
      </div>
    </Modal>
  );
};

export default ComingSoonPopup;
