import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import assetlineCapital from './images/AssetlineCapital.png';
import metlife from './images/metlife_logo.png';
import willIntl from './images/Will_Intl.png';
import zespri from './images/Zespri.png';
import instead from './images/Insead-min.png';
import mercedes from './images/mercedes.png';
import nab from './images/nab.png';
import pAndG from './images/P&G_Logo_SVG.svg-min.png';
import shisedo from './images/shisedo_logo-min.jpeg';

import css from './BrandRibbon.module.css';

const BrandRibbon = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <img src={assetlineCapital} alt="Assetline Capital logo" />
      <img src={metlife} alt="Met Life logo" />
      <img src={willIntl} alt="Will logo" />
      <img src={zespri} alt="Zespri logo" />
      <img src={instead} alt="Insead logo" />
      <img src={mercedes} alt="Mercedes logo" />
      <img src={nab} alt="Nab logo" />
      <img src={pAndG} alt="P&G logo" />
      <img src={shisedo} alt="Shisedo logo" />
    </div>
  );
};

BrandRibbon.defaultProps = { rootClassName: null, className: null };

BrandRibbon.propTypes = {
  rootClassName: string,
  className: string,
};

export default BrandRibbon;
