import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';

import css from './ReviewCard.module.css';

const ReviewCard = props => {
  const { className, thumbnail, id, review, role, signature, name } = props;

  return (
    <div className={classNames(css.root, className)}>
      <div>
        <div className={css.thumbnailWrapper}>
          <img src={thumbnail} alt={`${id} thumbnail`} loading="lazy" />
        </div>
        {signature && (
          <div className={css.signatureWrapper}>
            <img src={signature} alt="Footer logo" loading="lazy" />
          </div>
        )}
      </div>
      <div className={css.review}>
        <FormattedMessage id={review} />
        <div className={css.footer}>
          <div>
            {name && (
              <div className={css.name}>
                <FormattedMessage id={name} />
              </div>
            )}
            <div className={css.role}>
              <FormattedMessage id={role} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  className: string,
  thumbnail: string,
  id: string,
  review: string,
  role: string,
  signature: string,
  name: string,
};

ReviewCard.defaultProps = {
  className: null,
  thumbnail: null,
  id: null,
  review: null,
  role: null,
  signature: null,
  name: null,
};

export default ReviewCard;
