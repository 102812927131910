import React from 'react'
import classNames from 'classnames';
import { array, string } from 'prop-types';
import Arrow from './Images/Arrow.png'
import css from './ProcessUpdated.module.css';

const ProcessUpdated = (props) => {
  const { processContentList, className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div lassName={classes}>
      <ul className={css.contentBox}>
        {processContentList.map(
          ({ icon, title, description, itemClassName, iconBoxClassName, selected, buttons }, index) => (
            <li className={classNames(css.item, itemClassName)} key={index}>
              <div className={css.iconBoxclass}>

                <div
                  className={classNames(css.iconBox, iconBoxClassName, {
                    [css.activeItem]: selected,
                    [css.lastIcon]: index === processContentList.length - 1,
                  })}
                >
                  {icon}

                </div>
                {processContentList.length - 1 != index ?
                  <div className={css.iconBoxLine}>
                    <span className={css.iconSpan}><img src={Arrow} /></span>
                  </div> : null}
              </div>
              {/* <div className={css.added}>
              <p> {"0" + index}</p>
                            </div> */}
              <div className={processContentList.length - 1 == index ? `${css.rightShift}` : `${css.content} ${css.addcontent}`}>
                <p className={css.title}>
                  {title}
                </p>
                <p className={css.description}>{description}</p>
                <span>{buttons}</span>
              </div>
            </li>
          )
        )}
      </ul> </div>

  );
}
ProcessUpdated.defaultProps = {
  processContentList: [],
  className: null,
  rootClassName: null
};

ProcessUpdated.propTypes = {
  processContentList: array,
  className: string,
  rootClassName: string
};

export default ProcessUpdated;
