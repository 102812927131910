import React, { useState, useEffect, useRef } from 'react';
import { bool, object, func, shape, number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  LogoBanner,
  SectionOurPartnerships,
} from '../../components';
import { TopbarContainer } from '../../containers';

import firstFacebookImage from '../../assets/boldlyFacebook-1200x630-1.jpg'; 
import secondFacebookImage from '../../assets/boldlyFacebook-1200x630-2.jpg';
import firstTwitterImage from '../../assets/boldlyTwitter-600x314-1.jpg';
import secondTwitterImage from '../../assets/boldlyTwitter-600x314-2.jpg';
import css from './LandingPage.module.css';
import { COACH_ROLE, COACHEE_ROLE } from '../../marketplace-custom-config';
import CoachLandingPageSections from './CoachLandingPageSections';
import CoacheeLandingPageSections from './CoacheeLandingPageSections';
import MainLandingPageSections from './MainLandingPageSections';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import ComingSoonPopup from '../../components/ComingSoonPopup/ComingSoonPopup';
import { withViewport } from '../../util/contextHelpers';

const scrollIntoViewConfig = {
  behavior: 'smooth',
};

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUser,
    onManageDisableScrolling,
    currentUserFetched,
    isShowCoachHomepage,
    viewport,
  } = props;
  const [isLandToCoachHomePage, setIsLandToCoachHomePage] = useState(false);
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);
  const { siteToContact } = config;
  const sectionBoldlyWorksRef = useRef(null);
  const sectionCoachingCultureRef = useRef(null);

  useEffect(() => {
    setIsLandToCoachHomePage(false);
  }, [history.location.key]);

  const handleMoveToCoachingCultureSection = () => {
    sectionCoachingCultureRef.current.scrollIntoView(scrollIntoViewConfig);
  };

  const handleMoveToHowBoldlyWorksSection = () => {
    sectionBoldlyWorksRef.current.scrollIntoView(scrollIntoViewConfig);
  };

  useEffect(() => {
    if (history.location.state?.moveToCoachingCultureSection) {
      handleMoveToCoachingCultureSection();
    }

    if (history.location.state?.moveToHowBoldlyWorksSection) {
      handleMoveToHowBoldlyWorksSection();
    }

    const clearLocationState = () => {
      window.history.replaceState({}, '');
    }

    window.addEventListener('load', clearLocationState);

    return () => {
      window.removeEventListener('load', clearLocationState);
    }
  }, []);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImages = [
    `${config.canonicalRootURL}${firstFacebookImage}`,
    `${config.canonicalRootURL}${secondFacebookImage}`,
  ];
  const { role } = currentUser?.attributes.profile.publicData || {};
  const isCoachHomePage = role === COACH_ROLE || isLandToCoachHomePage || isShowCoachHomepage;
  const isCoacheeHomePage = role === COACHEE_ROLE;

  const handleRedirectToCoachLandingPage = () => {
    history.push(createResourceLocatorString('CoachLandingPage', routeConfiguration(), {}, {}));
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${firstFacebookImage}`, width: 1200, height: 630 },
        { url: `${config.canonicalRootURL}${secondFacebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${firstTwitterImage}`, width: 600, height: 314 },
        { url: `${config.canonicalRootURL}${secondTwitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage={isShowCoachHomepage ? 'CoachLandingPage' : 'LandingPage'}
            hideSearchbar={isCoachHomePage}
            handleMoveToCoachingCultureSection={handleMoveToCoachingCultureSection}
          />
        </LayoutWrapperTopbar>
        

        <LayoutWrapperMain>
          {currentUserFetched && (
            <>
              <div className={css.heroContainer}>
                {(isLandToCoachHomePage || isShowCoachHomepage) || (isCoacheeHomePage && !isShowCoachHomepage) ? (
                  <>
                  
                  
                  <SectionHero
                    className={css.hero}
                    history={history}
                    location={location}
                    role={role}
                    isLandToCoachHomePage={isLandToCoachHomePage || isShowCoachHomepage}
                    isShowCoacheeHomePage={isCoacheeHomePage && !isShowCoachHomepage}
                    // isHomePage={true}

                  />
                  </>
            
                ) : (
                  <SectionHero
                    className={css.hero}
                    isCustomContent="true"
                    theTitle="LandingPage.bannerTitle"
                    theSubTitle="LandingPage.bannerSubTitle"
                    isHomePage={true}
                  />
                )}
              </div>
              {(!isCoacheeHomePage || isShowCoachHomepage) && <LogoBanner />}
              <ul className={css.sections}>
                {isCoacheeHomePage && !isShowCoachHomepage ? (
                  <CoacheeLandingPageSections  />
                ) : isCoachHomePage ? (
                  <CoachLandingPageSections
                    isLandToCoachHomePage={isLandToCoachHomePage || isShowCoachHomepage}
                    currentUser={currentUser}
                    ref={sectionBoldlyWorksRef}
                  />
                ) : (
                  <MainLandingPageSections
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    refs={{ sectionBoldlyWorksRef, sectionCoachingCultureRef }}
                    isLandToCoachHomePage={isLandToCoachHomePage || isShowCoachHomepage}
                    viewport={viewport}
                  />
                )}
                <li className={css.section} />
                {(!isCoacheeHomePage || isShowCoachHomepage) && (
                  <li className={`${css.section} ${css.SectionsPartner} ${css.sectionOurPartner}`}>
                    <div className={css.sectionContent}>
                      <SectionOurPartnerships />
                    </div>
                    {!isCoachHomePage && (
                      <div className={css.sectionContentLink}>
                        <button className={css.link} onClick={handleRedirectToCoachLandingPage}>
                        </button>
                      </div>
                    )}
                  </li>
                )}
              </ul>
            </>
          )}
          <ComingSoonPopup
            isOpen={showComingSoonModal}
            onClose={() => setShowComingSoonModal(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={intl.formatMessage({ id: 'Modal.later' })}
            connectMail={siteToContact}
          />
        </LayoutWrapperMain> 
        
        <LayoutWrapperFooter>
          <Footer
            handleMoveToHowBoldlyWorksSection={handleMoveToHowBoldlyWorksSection}
            handleMoveToCoachingCultureSection={handleMoveToCoachingCultureSection}
          />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
  currentUser: null,
  currentUserFetched: false,
  isShowCoachHomepage: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  onManageDisableScrolling: func.isRequired,
  isShowCoachHomepage: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  currentUser: object,
  currentUserFetched: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser, currentUserFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    currentUserFetched
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withViewport,
)(LandingPageComponent);

export default LandingPage;
