import React, { useState } from 'react';
import { object, func, shape, number } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { useIntl } from 'react-intl';
import IMGAylam from './images/Aylam.jpeg';
import IMGavalyn from './images/avalyn.avif';
import IMGelana from './images/elana.avif';
import IMGstandardsaustralia from './images/standards-australia.png'
import BenefitsBOLDLY from '../../assets/Compressed Images/Benefits of the BOLDLY.png'
import Slider from 'react-slick';

import {
  SectionHowItWorks,
  SectionLocations,
  SectionLocation,
  SectionLocationFreeCoaching,
  SectionLocationCoachingCulture,
  SectionVideo,
  SectionButton,
  Modal,
  NamedLink,
  IconSearch,
  IconShortList,
  IconChemistry,
  IconUser,
  SwiperCarousel,
  Rating,
} from '../../components';
import { mainHomePageResources, clientReviewCarousel, mainLandingCarousel } from './resources';
import {
  pricingSearchModalContent,
  pricingGetACoachModalContent,
} from '../../components/EditListingWizard/EditListingWizardTab';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import BrandRibbon from '../../components/BrandRibbon/BrandRibbon';
import { SwiperSlide } from 'swiper/react';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import PETER from '../../assets/peter-cocach.jpeg'
import JARDEN from '../../assets/jarden-peter.jpeg'
import SectionHowItWorksUpdated from '../../components/SectionHowItWorksUpdated/SectionHowItWorksUpdated';
import GALAVYNWITHOUTBRAND from '../../assets/gavalyn_without_brand.jpg';
import PCC from '../../assets/pcc.jpeg'


import css from './LandingPage.module.css';

const MOBILE_SLIDES_PER_VIEW = 1.5;
const TABLET_SLIDES_PER_VIEW = 2.5;
const MOBILE_BREAK_POINT = 768;
const CAROUSEL_DELAY_TIME = 10000;

const MainLandingPageSections = props => {
  const { currentUser, onManageDisableScrolling, refs, handleOpenModal, viewport } = props;
  const [showPricingSearch, setShowPricingSearch] = useState(false);
  const [showPricingGetACoach, setShowPricingGetACoach] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const slidesPerView =
    viewport.width > MOBILE_BREAK_POINT ? TABLET_SLIDES_PER_VIEW : MOBILE_SLIDES_PER_VIEW;

  const {
    findQualifiedCoachesContent,
    onePlatformContent,
    mainButtonList,
    freeCoachingResourcesContent,
    mainIntroductionVideo,
    coachingCultureWorkshopsContent,
  } = mainHomePageResources;
  const testimonials = [
    // {
    //   name: "IMGAylam",
    //   company: "Company Name",
    //   text: "Dabus nisl aliquet congue tellus nascetur lectus sag. Dabus nisl aliquet congue tellus nascetur lectus sapgien mattis arcu dictum aug ue volutpat felis etiam suspendisse rhoncus mauris dignissim ante.Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur aliquet quam id dui posuere blandit. Sed porttitor lectus nibh.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.",
    //   logo: IMGAylam,
    //   brandLogo: IMGstandardsaustralia,
    //   rating: 5
    // },
    {
      name: "Peter",
      company: "Chief Talent & Learning Officer Jardine Matheson, Hong Kong",
      text: "We have worked with the BOLDLY team for 5 years now across many of our development programmes for Jardines talent. This covers 1-1 coaching assignments, coaching on demand for our graduates, coaching and advanced coaching learning programmes for Managers, supporting us to structure and run our mentoring programmes and more recently develop a fabulous workshop to build career counselling capabilities in our HR teams.BOLDLY has been a great partner to us on this journey and I wouldn't hesitate to recommend their services to any organisation looking to use coaching and mentoring as part of their development journey.",
      logo: PETER,
      brandLogo: JARDEN,
      rating: 4

    },
    {
      name: "Avalyn",
      company: "",
      text: "I've enjoyed being a coach on the BOLDLY platform as it has provided me with opportunities to be considered for and work on some interesting assignments, both locally and across countries. The best thing is the flexibility of being able to set my own rates, which sets it apart from other coaching platforms.",
      logo: IMGavalyn,
      brandLogo: PCC,
      rating: 5
    }
  ];
  const [isFormOpened, setIsFormOpened] = useState(false);

  const handleButtonClick = () => {
    // Check if form is already opened
    if (!isFormOpened) {
      // Mark form as opened
      setIsFormOpened(true);

      // Delay the form opening by 1 second (1000 milliseconds)
      setTimeout(() => {
        loadZCPopup(
          '3zda9b3f9658e3a9eba483d7d628872de709a3a2700197615d777ba75625c5efc5',
          'ZCFORMVIEW',
          '3zf90b12cfe9722066188ef390685a0fa7'
        );
      }, 1000);
    } else {
      console.log("Form has already been opened.");
    }
  };
  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={`${className} ${css.customArrow} ${css.customNextArrow}`}
      onClick={onClick}
    >
      <p> &gt;</p>

    </div>
  );

  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={`${className} ${css.customArrow} ${css.customPrevArrow}`}
      onClick={onClick}
    >
      <p>
        &lt;
      </p>

    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 30000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />

  };
  const engagementListingProcessContent = [
    {
      icon: <IconSearch className={css.iconSearch} />,
      title: <FormattedMessage id="EditListingProcessPanel.searchTitle" />,
      description: (
        <FormattedMessage
          id="EditListingProcessPanel.searchDescriptionForEngagement"
        // values={{
        //   pricingSearchLink: (
        //     <button className={css.modalLink} onClick={() => setShowPricingSearch(true)}>
        //       <FormattedMessage id="EditListingProcessPanel.learnAboutMarketplacePricing" />
        //     </button>
        //   ),
        // }}
        />
      ),
      buttons: (
        <button className={css.modalLink} onClick={() => setShowPricingSearch(true)}>
          <FormattedMessage id="EditListingProcessPanel.learnAboutMarketplacePricing" />
        </button>
      ),
    },
    {
      icon: <IconShortList />,
      title: <FormattedMessage id="EditListingProcessPanel.listTitle" />,
      description: <FormattedMessage id="EditListingProcessPanel.listDescriptionForEngagement" />,
    },
    {
      icon: <IconChemistry />,
      title: <FormattedMessage id="EditListingProcessPanel.chemistryTitle" />,
      description: (
        <FormattedMessage id="EditListingProcessPanel.chemistryDescriptionForEngagement" />
      ),
    },
    {
      icon: <IconUser />,
      title: <FormattedMessage id="EditListingProcessPanel.coachTitle" />,
      description: (
        <FormattedMessage
          id="EditListingProcessPanel.coachDescriptionForEngagement"
        // values={{
        //   pricingGetACoachLink: (
        //     <button className={css.modalLink} onClick={() => setShowPricingGetACoach(true)}>
        //       <FormattedMessage id="EditListingProcessPanel.learnAboutCoachPayments" />
        //     </button>
        //   ),
        // }}
        />
      ),
      buttons: (
        <button className={css.modalLink} onClick={() => setShowPricingGetACoach(true)}>
          <FormattedMessage id="EditListingProcessPanel.learnAboutCoachPayments" />
        </button>
      )
    },
  ];

  const onPricingModalClose = () => {
    setShowPricingSearch(false);
    setShowPricingGetACoach(false);
  };

  const onClickItemsInOnePlatformSection = params => {
    const { id } = params;
    history.push({
      pathname: createResourceLocatorString('CoachingFormatsPage', routeConfiguration(), {}, {}),
      state: { moveToSection: id },
    });
  };

  const pricingSearchContent = (
    <div className={css.modalContentContainer}>
      {pricingSearchModalContent}
      <NamedLink name="NewEngagementPage" className={css.button}>
        <FormattedMessage id="SectionHero.createEngagement" />
      </NamedLink>
    </div>
  );

  const pricingGetACoachContent = (
    <div className={css.modalGetACoachContainer}>
      {pricingGetACoachModalContent}
      <NamedLink name="NewEngagementPage" className={css.button}>
        <FormattedMessage id="SectionHowItWorks.getStarted" />
      </NamedLink>
      <p className={css.optional}>
        <FormattedMessage id="SectionHowItWorks.optional" />
      </p>
    </div>
  );

  const titleH1 = 'h1 ' + css.textLight;

  const iconBoxListBenefits = intl
    .formatMessage({ id: 'LandingPage.benefitsList' })
    .split('|')
    .map(option => option.trim());

  const displayListBenefits = iconBoxListBenefits.map((item, index) => (
    <li key={index} className={css.item}>
      <span>{item}</span>
    </li>
  ));

  return (
    <>
      <li className={css.section}>
        <div className={css.greyBackground}>
          {/* <h4> */}
          <SectionLocations
            title={
              <>
                <span className={css.titlehighlight}>
                  <FormattedMessage id="SectionFindQualifiedCoaches.title" />
                </span>

                <br />
                <span className={css.titlehighlight1}>
                  <FormattedMessage id="SectionFindQualifiedCoaches.title1" />
                </span>
              </>
            }
            subTitle={
              <>
                <FormattedMessage
                  id="SectionFindQualifiedCoaches.newSubTitle"
                  values={{
                    lineBreak: <br />,
                  }}
                />
                <br />
                <br />

                <FormattedMessage
                  id="SectionFindQualifiedCoaches.subTitle1"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </>
            }
            contentList={findQualifiedCoachesContent}
            currentUser={currentUser}
            className={classNames(css.sectionContentWithoutStraightLine, css.sectionFirstChild)}
            onClickItems={handleOpenModal}
          />
          {/* </h4> */}
        </div>
      </li>
      <li className={css.section}>
        <div className={css.sectionGradientTopBottom}>
          <div className={css.sectionGradientTopBottomContainer}>
            <div>
              <div>
                <div className={`${css.row}`}>
                  <div className={`${css.col} ${css.colleft}`}>
                    <h2 className={classNames(titleH1, css.titleH)}>{<FormattedMessage id="LandingPage.benefitsTitle" />}</h2>
                    <h2 className={classNames(titleH1, css.titleH2)}>
                      {<FormattedMessage id="LandingPage.benefitsTitle1" />}
                    </h2>
                    <p className={css.textLight}>
                      <FormattedMessage id="LandingPage.benefitsSubTitle" />
                    </p>
                    <p className={css.textLight}>
                      <FormattedMessage id="LandingPage.benefitsSubTitle1" />
                    </p>
                    <p className={`${css.textLight} ${css.benefitsSubTitle2}`}>
                      <FormattedMessage id="LandingPage.benefitsSubTitle2" />
                    </p>
                  </div>
                  <div className={`${css.col} ${css.colright}`}>
                    <img src={BenefitsBOLDLY} />
                  </div>
                </div>
                <div className={css.GetCoachButton} >

                  <div className={css.GetButton} dangerouslySetInnerHTML={{
                    __html: `<div id='embedLink' style="width:100%;height:100%;border:none;cursor: pointer !important;">
    <button type="button" purpose="nrmlBtn" class=""  style=" border: none;
  height: 60px;
  width: 202px;
  background-color: #7c9bff;
  border-radius: 30px;
  font-weight: 800;
  font-size: large;
  cursor: pointer;
  transition: all 150ms ease-out;" changetype="EMBED_BUTTON" id="EMBED_BUTTON" name="EMBED_BUTTON"
      value="Get started" formopenin="Popup" btntype="nrmlBtn"
      onclick="zc_loadForm('campaigns.zoho.com','https://npqusd-glf.maillist-manage.com/ua/Optin?od=11287ecc786fdd&amp;zx=12859b91f&amp;tD=110bf14b6ae55d3c1&amp;sD=110bf14b6ae66bfd9')"
      bgcolor="#f74765">
      <span class="zceditcnt" style="">Get Started</span>
    </button>`}}></div>
                  {/* <button onClick={handleButtonClick} className={css.GetButton}>GET STARTED</button> */}
                </div>

              </div>

            </div>
            {/* <div class={css.container}>
              <div className={css.row}>
                <div className={`${css.col} ${css.colleft}`}>
                  <h2 className={classNames(titleH1, css.titleH)}>{<FormattedMessage id="LandingPage.benefitsTitle" />}</h2>
                  <h2 className={classNames(titleH1, css.titleH2)}>
                    {<FormattedMessage id="LandingPage.benefitsTitle1" />}
                  </h2>
                  <p className={css.textLight}>
                    <FormattedMessage id="LandingPage.benefitsSubTitle" />
                  </p>
                  <p className={css.textLight}>
                    <FormattedMessage id="LandingPage.benefitsSubTitle1" />
                  </p>
                  <p className={`${css.textLight} ${css.benefitsSubTitle2}`}>
                    <FormattedMessage id="LandingPage.benefitsSubTitle2" />
                  </p>
                </div>
                <div className={`${css.col} ${css.colright}`}>
                  <img src={BenefitsBOLDLY} />
                </div>
                <div className={css.GetCoachButton}>

                  <button onClick={handleButtonClick} className={css.GetButton}>GET STARTED</button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </li>
      {/* ===================================== our reviews============================== */}
      <li className={css.section}>
        <div className={classNames(css.ourReviewsseaction)}>
          <div className={css.ourReviews}>
            <h2 className={classNames(titleH1, css.titleourReview)}>{<FormattedMessage id="ChemistrySections.ourReviews" />}</h2>
            <h2 className={classNames(css.titleourReviews)}>
              {<FormattedMessage id="ChemistrySections.ourReviews1" />}
            </h2>

          </div>
          <div className={`${css.container} ${css.containers}`}>
            <p>
              <FormattedMessage id="SectionFindQualifiedCoaches.ourreviewTitle" />
            </p>
            <p>
              <FormattedMessage id="SectionFindQualifiedCoaches.ourreviewsTitle" />
            </p>
          </div>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className={css.card}>
                <div className={css.testimonialcard}>
                  <div className={css.testimoniallogo}>
                    <img src={testimonial.logo} alt={`${testimonial.company} logo`} style={{ width: '70px', height: '70px', marginBottom: '10px' }} />
                  </div>
                  <div className={css.testimonialname}>
                    <div>
                      <h3>{testimonial.name}</h3>
                      <h4>{testimonial.company}</h4>
                    </div>
                  </div>
                </div>
                <div className={css.testimonialtext}>
                  <p>{testimonial.text}</p>
                </div>
                <hr />
                <div className={css.rating}>
                  <div className={css.brandLogo}>
                    <img src={testimonial.brandLogo} alt={`${testimonial.company} brand`} />
                  </div>
                  <div>
                    {"★".repeat(5)}
                    {/* {"☆".repeat(5 - testimonial.rating)} */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </li>

      <li className={css.section}>
        <div className={css.sectionHowItWorkAdd}>
          <div className={`${css.sectionContent} ${css.sectioncontentHowItWork}`} ref={refs.sectionBoldlyWorksRef}>

            <SectionHowItWorksUpdated processContentList={engagementListingProcessContent} />
          </div>
        </div>
        <div
          className={classNames(css.sectionContentInside, css.sectionContentWithoutStraightLine)}
        >
          <SectionLocation

            title={
              <>
                <h2 className={`${css.SectionOnePlatform} h1`}>
                  <FormattedMessage id="SectionOnePlatform.title" />
                </h2>
                <h2 className={`${css.SectionOnePlatformtitle1} h1`}>
                  <FormattedMessage id="SectionOnePlatform.title1" />
                </h2>
              </>
            }
            subTitle={<FormattedMessage id="SectionOnePlatform.newSubTitle" />}
            contentList={onePlatformContent}
            onClickItems={onClickItemsInOnePlatformSection}
          />
        </div>
        <SectionButton buttonList={mainButtonList} handleClickButtons={handleOpenModal} />
        <div className={classNames(css.sectionContentWithoutStraightLine)}>
          <SectionLocationFreeCoaching
            title={
              <>
                <h2 className={`${css.SectionOnePlatform} h1`}>{<FormattedMessage id="SectionFreeCoachingResources.title" />}</h2>
                <h2 className={`${css.SectionOnePlatformtitle1} h1`}>{<FormattedMessage id="SectionFreeCoachingResources.title1" />}</h2>
              </>
            }
            subTitle={
              <p className={css.SectionFreeCoachingResources}>
                <FormattedMessage id="SectionFreeCoachingResources.newSubTitle" />
              </p>}
            contentList={freeCoachingResourcesContent}
          />
        </div>
        <SectionVideo {...mainIntroductionVideo} ishomeVideoStars={true} />
        <div className={classNames(css.greyBackground, css.greyBackgrounds)}>
          <div
            className={classNames(css.sectionContentWithoutStraightLine)}
            ref={refs.sectionCoachingCultureRef}
          >

            <SectionLocationCoachingCulture
              title={
                <>
                  <h2 className={`${css.SectionOnePlatform} h1`}>{<FormattedMessage id="SectionCoachCultureWorkshops.title" />}</h2>
                  <h2 className={`${css.SectionOnePlatformtitle1} h1`}>{<FormattedMessage id="SectionCoachCultureWorkshops.title1" />}</h2>
                </>
              }
              subTitle={
                <p className={css.SectionFreeCoachingResources}>
                  <FormattedMessage id="SectionCoachCultureWorkshops.subTitle" />
                </p>}
              contentList={coachingCultureWorkshopsContent}

            />
          </div>
        </div>
      </li>
      <Modal
        id="EditListingWizard.pricingModal"
        isOpen={showPricingSearch || showPricingGetACoach}
        onClose={onPricingModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {showPricingSearch && pricingSearchContent}
        {showPricingGetACoach && pricingGetACoachContent}
      </Modal>
    </>
  );
};

MainLandingPageSections.defaultProps = {
  currentUser: null,
  handleOpenModal: null,
};

MainLandingPageSections.propTypes = {
  currentUser: object,
  onManageDisableScrolling: func.isRequired,
  handleOpenModal: func,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default MainLandingPageSections;
