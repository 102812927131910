import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  SectionHowItWorks,
  SectionLocations,
  SectionVideo,
  SectionButton,
  SwiperCarousel,
} from '../../components';
import { coachHomePageResources, coachReviewCarousel } from './resources';
import { coachListingProcessContent } from '../../components/EditListingWizard/EditListingWizardTab';
import { bool, object, func } from 'prop-types';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import { SwiperSlide } from 'swiper/react';
import ZohoBanner from './ZohoBanner';
import SectionHowItWorksUpdated from '../../components/SectionHowItWorksUpdated/SectionHowItWorksUpdated';
import ExtraCoachSectionfrom from '../../components/ExtraBecomeaCoach/ExtraBecomeacoach'

import css from './LandingPage.module.css';

const CAROUSEL_DELAY_TIME = 10000;

const CoachLandingPageSections = React.forwardRef((props, ref) => {
  const { currentUser, isLandToCoachHomePage } = props;
  const {
    becomeCoachContent,
    coachIntroductionVideo,
    coachButtonList,
    yourPeersContent,
    wantResourcesContent,
  } = coachHomePageResources;

  return (
    <>
      <li className={css.section}>
        <div className={css.sectionContentFirstChild}>
          <SectionLocations
            title={<FormattedMessage id="SectionBecomeBoldlyCoach.title" />}
            subTitle={<FormattedMessage id="SectionBecomeBoldlyCoach.subTitle" />}
            contentList={becomeCoachContent}
          />
        </div>
        <SwiperCarousel
          className={css.fitSwiperContainer}
          slidesPerView={1}
          spaceBetween={24}
          navigation
          loop
          autoplay={{
            delay: CAROUSEL_DELAY_TIME,
          }}
        >
          {coachReviewCarousel.map(carouselData => (
            <SwiperSlide key={carouselData.id}>
              <ReviewCard {...carouselData} />
            </SwiperSlide>
          ))}
        </SwiperCarousel>
        <SectionVideo {...coachIntroductionVideo} />
      </li>
      <li className={css.section}>
        <div className={classNames(css.sectionContent, css.PaddingCoach)} ref={ref}>
          <SectionHowItWorksUpdated processContentList={coachListingProcessContent} isCoach={true} />
        </div>
        <div className={classNames(css.sectionContentLink, css.boldFirstLine, css.coachFont)}>
          <FormattedMessage id="CoachLandingPageSections.text" values={{ lineBreak: <br /> }} />
        </div>
        <div className={classNames(css.PaddingCoach)}>
        <ExtraCoachSectionfrom />
        </div>
        {/* {isLandToCoachHomePage && <SectionButton buttonList={coachButtonList} />} */}
        <div className={css.greyBackground}>
          <SectionLocations
            title={<FormattedMessage id="SectionYourPeers.title" />}
            contentList={yourPeersContent}
            currentUser={currentUser}
            className={classNames(css.sectionContentWithoutStraightLine, css.greyBackground)}
          />
        </div>
        <ZohoBanner />
        <div className={css.sectionContentWithoutStraightLine}>
          <SectionLocations
            title={<FormattedMessage id="SectionWantResources.title" />}
            contentList={wantResourcesContent}
          />
        </div>
      </li>
    </>
  );
});

CoachLandingPageSections.defaultProps = {
  isLandToCoachHomePage: false,
  currentUser: null,
  handleClickButtons: () => { },
};

CoachLandingPageSections.propTypes = {
  isLandToCoachHomePage: bool,
  currentUser: object,
  handleClickButtons: func,
};

export default CoachLandingPageSections;
