import React from 'react';
import classNames from 'classnames';
import { FormattedMessage} from '../../util/reactIntl';
import {
  SectionHowItWorks,
  SectionLocations,
  SectionVideo,
  IconSearch,
  IconShortList,
  IconChemistry,
  IconUser
} from '../../components';

import css from './LandingPage.module.css';
import { coacheeHomePageResources } from './resources';

const coacheeListingProcessContent = [
  {
    icon: <IconSearch className={css.iconSearch} />,
    title: <FormattedMessage id="EditListingProcessPanel.searchTitle" />,
    description: <FormattedMessage id="CoacheeLandingPage.searchDescription" />,
  },
  {
    icon: <IconShortList />,
    title: <FormattedMessage id="EditListingProcessPanel.listTitle" />,
    description: <FormattedMessage id="CoacheeLandingPage.listDescription" />,
  },
  {
    icon: <IconChemistry />,
    title: <FormattedMessage id="EditListingProcessPanel.chemistryTitle" />,
    description: <FormattedMessage id="CoacheeLandingPage.chemistryDescription" />,
  },
  {
    icon: <IconUser />,
    title: <FormattedMessage id="EditListingProcessPanel.coachTitle" />,
    description: <FormattedMessage id="CoacheeLandingPage.coachDescription" />,
  },
];

const CoacheeLandingPageSections = () => {
  const {
    whatIsCoachingContent,
    coacheeIntroductionVideo,
    yourCoachingResourcesContent,
  } = coacheeHomePageResources;

  return (
    <>
      <li className={css.section}>
        <div className={classNames(css.sectionContentWithoutStraightLine, css.sectionFirstChild)}>
          <SectionLocations
            title={<FormattedMessage id="SectionWhatIsCoaching.title" />}
            subTitle={
              <FormattedMessage id="SectionWhatIsCoaching.subTitle" values={{ newline: <br /> }} />
            }
            contentList={whatIsCoachingContent}
          />
        </div>
      </li>
      <li className={css.section}>
        <div className={css.sectionContent}>
          <SectionHowItWorks
            isShowTitleForCoachee
            processContentList={coacheeListingProcessContent}
          />
        </div>
        <SectionVideo {...coacheeIntroductionVideo} />
        <div className={css.sectionContentWithoutStraightLine}>
          <SectionLocations
            title={<FormattedMessage id="SectionYourCoachingResources.title" />}
            contentList={yourCoachingResourcesContent}
          />
        </div>
      </li>
    </>
  );
};

export default CoacheeLandingPageSections;
