import React from 'react';
import { array, bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
// import { Process } from '../../components';
import { useHistory } from 'react-router-dom';
import ProcessUpdated from '../ProcessUpdated/ProcessUpdated';


import css from './SectionHowItWorksUpdated.module.css';

const SectionHowItWorksUpdated = props => {
    const { rootClassName, className, processContentList, isShowTitleForCoachee, isCoach } = props;

    const history = useHistory();


    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.title}>
                {isShowTitleForCoachee ? (
                    <FormattedMessage id="SectionHowItWorks.titleForCoachee" />
                ) : (
                    <>
                        <div >
                            <span className={`${css.h11} h1`}>
                                {
                                    <FormattedMessage id="SectionHowItWorks.titleLineOne" />

                                }

                            </span>
                            <span className={`${css.headingTexts} h1`}>
                                {<FormattedMessage id="SectionHowItWorks.titleLineTwo" />
                                }

                            </span>
                        </div>

                    </>
                )}
            </div>
            <p className={css.SectionHowItWorksUpdatedtitleBottom}>
                <FormattedMessage id="SectionHowItWorks.titleBottom" />
            </p>
            <p className={css.SectionHowItWorksUpdatedtitleBottom}>
                <FormattedMessage id="SectionHowItWorks.titleBottom1" />

            </p>

            <ProcessUpdated processContentList={processContentList} />
            <p className={css.SectionHowItWorksUpdatedtitleBottom}>
                <FormattedMessage id="SectionHowItWorks.newSubTitle" />
            </p>
            {isCoach ? null : <div className={css.GetCoachButton}>

                <button className={css.GetButton} onClick={(e) => history.push('/s')}> <FormattedMessage id="TransactionPanel.getACoach" /></button>
            </div>}

        </div>
    );
};

SectionHowItWorksUpdated.defaultProps = {
    rootClassName: null,
    className: null,
    processContentList: [],
    isShowTitleForCoachee: false,
};

SectionHowItWorksUpdated.propTypes = {
    rootClassName: string,
    className: string,
    processContentList: array,
    isShowTitleForCoachee: bool,
};

export default SectionHowItWorksUpdated;