import React from 'react';
import { Helmet } from 'react-helmet-async';

import css from './ZohoBanner.module.css';
import classNames from 'classnames';

const ZohoBanner = () => {
  return (
    <>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://npqusd-glf.maillist-manage.com/js/optin.min.js"
          onload="setupSF('sf3z1498ae5ba53df4d1d67f1a510c0eef6eec53d5ea4a980ffdd5f89e38fb7df141','ZCFORMVIEW',false,'light',false,'0')"
        />
      </Helmet>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.quick_form_5_css * { -webkit-box-sizing: border-box !important; -moz-box-sizing: border-box !important; box-sizing: border-box !important; overflow-wrap: break-word }@media only screen and (max-width: 600px) {.quick_form_5_css[name="SIGNUP_BODY"] { width: 100% !important; min-width: 100% !important; margin: 0px auto !important; padding: 0px !important } .SIGNUP_FLD { width: 90% !important; margin: 10px 5% !important; padding: 0px !important } .SIGNUP_FLD input { margin: 0 !important } }',
        }}
      />
      <div
        id="sf3z1498ae5ba53df4d1d67f1a510c0eef6eec53d5ea4a980ffdd5f89e38fb7df141"
        data-type="signupform"
      >
        <div id="customForm">
          <div className={classNames('quick_form_5_css', css.zohoBanner)} name="SIGNUP_BODY">
            <div className={css.wrapper}>
              <div id="SIGNUP_HEADING" className={css.signupHeading}>
                Join Our #CoachCalling Newsletter
              </div>
              <div style={{ position: 'relative' }}>
                <div
                  id="Zc_SignupSuccess"
                  style={{
                    display: 'none',
                    position: 'absolute',
                    marginLeft: '4%',
                    width: '90%',
                    backgroundColor: 'white',
                    padding: 3,
                    border: '3px solid rgb(194, 225, 154)',
                    marginTop: 10,
                    marginBottom: 10,
                    wordBreak: 'break-all',
                  }}
                >
                  <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                    <tbody>
                      <tr>
                        <td width="10%">
                          <img
                            className="successicon"
                            src="https://npqusd-glf.maillist-manage.com/images/challangeiconenable.jpg"
                            align="absmiddle"
                          />
                        </td>
                        <td>
                          <span
                            id="signupSuccessMsg"
                            style={{
                              color: 'rgb(73, 140, 132)',
                              fontFamily: 'sans-serif',
                              fontSize: 14,
                              wordBreak: 'break-word',
                            }}
                          >
                            &nbsp;&nbsp;Thank you for Signing Up
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <form
                method="POST"
                id="zcampaignOptinForm"
                style={{ margin: 0, width: '100%' }}
                action="https://npqusd-glf.maillist-manage.com/weboptin.zc"
                target="_zcSignup"
                className={css.signupForm}
              >
                <div
                  style={{
                    backgroundColor: 'rgb(255, 235, 232)',
                    padding: 10,
                    color: 'rgb(210, 0, 0)',
                    fontSize: 11,
                    margin: '20px 10px 0px',
                    border: '1px solid rgb(255, 217, 211)',
                    opacity: 1,
                    display: 'none',
                  }}
                  id="errorMsgDiv"
                >
                  Please correct the marked field(s) below.
                </div>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'inline-block',
                  }}
                  className="SIGNUP_FLD"
                >
                  <input
                    type="text"
                    style={{
                      fontSize: 16,
                      width: '100%',
                      height: '100%',
                      zIndex: 4,
                      outline: 'none',
                      padding: '5px 10px',
                      color: 'rgb(113, 106, 106)',
                      textAlign: 'left',
                      fontFamily: 'Arial',
                      backgroundColor: 'rgb(255, 255, 255)',
                      boxSizing: 'border-box',
                      outline: 'none',
                      border: 'none',
                    }}
                    placeholder="Email"
                    changeitem="SIGNUP_FORM_FIELD"
                    name="CONTACT_EMAIL"
                    id="EMBED_FORM_EMAIL_LABEL"
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'inline-block',
                  }}
                  className="SIGNUP_FLD"
                >
                  <input
                    type="text"
                    style={{
                      fontSize: 16,
                      width: '100%',
                      height: '100%',
                      zIndex: 4,
                      outline: 'none',
                      padding: '5px 10px',
                      color: 'rgb(113, 106, 106)',
                      textAlign: 'left',
                      fontFamily: 'Arial',
                      backgroundColor: 'rgb(255, 255, 255)',
                      boxSizing: 'border-box',
                      outline: 'none',
                      border: 'none',
                    }}
                    placeholder="Name"
                    changeitem="SIGNUP_FORM_FIELD"
                    name="FIRSTNAME"
                    id="FIRSTNAME"
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    width: 200,
                    height: 50,
                    margin: '0 auto',
                  }}
                  className={classNames('SIGNUP_FLD', css.signupButton)}
                >
                  <input
                    type="button"
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      height: '100%',
                      zIndex: 5,
                      border: 0,
                      color: 'rgb(255, 255, 255)',
                      cursor: 'pointer',
                      outline: 'none',
                      fontSize: 14,
                      backgroundColor: 'rgb(124, 155, 255)',
                      borderRadius: 1,
                      fontSize: 16,
                    }}
                    name="SIGNUP_SUBMIT_BUTTON"
                    id="zcWebOptin"
                    defaultValue="Join Now"
                  />
                </div>
                <input type="hidden" id="fieldBorder" defaultValue="" />
                <input
                  type="hidden"
                  id="submitType"
                  name="submitType"
                  defaultValue="optinCustomView"
                />
                <input type="hidden" id="emailReportId" name="emailReportId" defaultValue="" />
                <input type="hidden" id="formType" name="formType" defaultValue="QuickForm" />
                <input type="hidden" name="zx" id="cmpZuid" defaultValue="12859b91f" />
                <input type="hidden" name="zcvers" defaultValue={3.0} />
                <input type="hidden" name="oldListIds" id="allCheckedListIds" defaultValue="" />
                <input type="hidden" id="mode" name="mode" defaultValue="OptinCreateView" />
                <input type="hidden" id="zcld" name="zcld" defaultValue="110bf14b6ae56a845" />
                <input type="hidden" id="zctd" name="zctd" defaultValue="110bf14b6ae55d3c1" />
                <input type="hidden" id="document_domain" defaultValue="" />
                <input type="hidden" id="zc_Url" defaultValue="npqusd-glf.maillist-manage.com" />
                <input type="hidden" id="new_optin_response_in" defaultValue={2} />
                <input type="hidden" id="duplicate_optin_response_in" defaultValue={2} />
                <input
                  type="hidden"
                  name="zc_trackCode"
                  id="zc_trackCode"
                  defaultValue="ZCFORMVIEW"
                />
                <input
                  type="hidden"
                  id="zc_formIx"
                  name="zc_formIx"
                  defaultValue="3z1498ae5ba53df4d1d67f1a510c0eef6eec53d5ea4a980ffdd5f89e38fb7df141"
                />
                <input type="hidden" id="viewFrom" defaultValue="URL_ACTION" />
                <span style={{ display: 'none' }} id="dt_CONTACT_EMAIL">
                  1,true,6,Contact Email,2
                </span>
                <span style={{ display: 'none' }} id="dt_FIRSTNAME">
                  1,false,1,First Name,2
                </span>
                <span style={{ display: 'none' }} id="dt_LASTNAME">
                  1,false,1,Last Name,2
                </span>
              </form>
            </div>
          </div>
        </div>
        <img
          src="https://npqusd-glf.maillist-manage.com/images/spacer.gif"
          id="refImage"
          onload="referenceSetter(this)"
          style={{ display: 'none' }}
        />
      </div>
      <input type="hidden" id="signupFormType" defaultValue="QuickForm_Horizontal" />
      <div
        id="zcOptinOverLay"
        oncontextmenu="return false"
        style={{
          display: 'none',
          textAlign: 'center',
          backgroundColor: 'rgb(0, 0, 0)',
          opacity: '0.5',
          zIndex: 100,
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          height: 988,
        }}
      />
      <div
        id="zcOptinSuccessPopup"
        style={{
          display: 'none',
          zIndex: 9999,
          width: 800,
          height: '40%',
          top: 84,
          position: 'fixed',
          left: '26%',
          backgroundColor: '#FFFFFF',
          borderColor: '#E6E6E6',
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: '0 1px 10px #424242',
          padding: 35,
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '-16px',
            right: '-14px',
            zIndex: 99999,
            cursor: 'pointer',
          }}
          id="closeSuccess"
        >
          <img src="https://npqusd-glf.maillist-manage.com/images/videoclose.png" />
        </span>
        <div id="zcOptinSuccessPanel" />
      </div>
    </>
  );
};

export default ZohoBanner;
